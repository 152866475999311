import { Page } from "static/js/app/modules/page";
import { VehicleComparison } from "static/js/app/modules/vehicleComparison";
import Common from "static/js/app/pages/common";
import StringFormatting from "static/js/app/utils/stringFormatting/stringFormatting";
import VehicleStringFormatting from "static/js/app/utils/stringFormatting/vehicleStringFormatting";
import DOMPurify from "dompurify";
import { VehicleDetailsPageConfig } from "static/js/app/pages/vehicle";
import * as Models from "static/js/app/models/__index";
import VehicleFinanceQuotes from "themes/module_finance_plugin/static/js/vehicle_finance_quotes";
import VehicleFinanceChecker from "themes/module_finance_plugin/static/js/vehice_finance_checker";
import { buildDealerContactPhoneDetails } from "themes/module_vehicle_branch_details/static/js/dealer-contact-phone-template";
import { DealerBranchPublicInfo } from "static/js/app/models/dealerInfo";
import { SiteConfig } from "static/js/app/hugoSettings/siteConfig";
import { FinanceConfig } from "themes/module_finance_plugin/static/js/financeConfig";

export default class VehicleSingleTemplate {
    public static init( templateTargetEl: HTMLElement) {

        const accordions = templateTargetEl.querySelector<HTMLElement>(".accordions");
        this.setUpAccordions(accordions);

        this.wireUpCompareButton();
    }

    private static wireUpCompareButton() {
        const compareButton = document.querySelector<HTMLElement>(".compare-button");
        if (compareButton != null)
        {


        this.toggleVehicleCompareButtonState(this.checkIfSaved(compareButton.getAttribute("data-vehId")), compareButton)

        $(".compare-button").on("click", (evt) => {
            evt.preventDefault();
            let btnEl = (evt.target.classList.contains("compare-button")) ? evt.target : $(evt.target).parents(".compare-button:first")[0];
            const vehId = btnEl.getAttribute("data-vehId");
            const error = VehicleComparison.toggleVehicleCompare(vehId);
            if (error != null) {
                console.log(error);
            }
            Common.updateSavedVehiclesCount();
            this.toggleVehicleCompareButtonState(this.checkIfSaved(vehId), btnEl);
        });
        }
    }



    private static buildVehicleDetailsHtml(vehicle: Models.Vehicle, pageConfig: VehicleDetailsPageConfig, templateEl: HTMLElement, branch: DealerBranchPublicInfo, financeConfig: FinanceConfig): string {
        const dummyEl = document.createElement("span");

        dummyEl.innerHTML = this.buildListHtml(vehicle, pageConfig, templateEl, branch,financeConfig);

        // remove empty overview list items
        this.removeEmptySpecListEls(dummyEl.querySelector(".specs-list"));

        const tabsUl = dummyEl.querySelector(".tabs--node-used-vehicle-tabbed-content");
        const featuresTabLi = tabsUl?.querySelector("a[href*='#features']")?.parentElement;
        const tabContentSectionsContainer = dummyEl.querySelector(".tabs-content--node-used-vehicle-tabbed-content") as HTMLElement;

        // remove feature categories if they are empty
        this.removeUnusedFeatures(
            dummyEl.querySelector("#features"),
            tabsUl as HTMLUListElement,
            tabContentSectionsContainer,
            featuresTabLi,
            vehicle.features,
            this.buildVehicleDescription(vehicle)
        );

        // remove tech spec list items
        const techSpecEl = dummyEl.querySelector(".technical-specs") as HTMLElement;

        this.removeEmptyTechSpecs(techSpecEl);

        return dummyEl.innerHTML;
    }

    private static buildVehicleDescription(vehicle: Models.Vehicle): string {
        return (vehicle.description !== null && vehicle.description !== 'None' && typeof vehicle.description !== 'undefined')
            ? "<div class=\"l-node-used-vehicle--full__additional-vehicle-details\"><h3>" + vehicle.manufacturer_display + " " + vehicle.model_display + " Additional Information</h3><div class=\"field field-name-vehicle-additional-info\">" + vehicle.description + "</div></div>"
            : "";
    }

    private static buildListHtml(vehicle: Models.Vehicle, pageConfig: VehicleDetailsPageConfig, templateEl: HTMLElement, branch: DealerBranchPublicInfo, financeConfig: FinanceConfig) {
        const gallery = this.getGalleryImages(vehicle, pageConfig.galleryImageSize);
        const thumbs = this.getGalleryThumbs(vehicle, pageConfig.galleryThumbSize);
        const videoId = this.tryExtractVideoId(vehicle.youtube_link);
        const hasVideo = (videoId != null);
        const isSaved = this.checkIfSaved(vehicle.id);
        let saveEmClass = (isSaved) ? "fas" : "far";
        let saveSectionExtraClasses = (isSaved) ? " saved-button success" : "";
        let saveText = (isSaved) ? "Compared" : "Compare";
        let save: string = `<a class="${pageConfig.saveButtonBasicStyleClass} full-details${saveSectionExtraClasses}" data-vehId="${vehicle.id}"><i class="${saveEmClass} fa-star" aria-hidden="true"></i><span> ${saveText}</span></a>`;

        const description = this.buildVehicleDescription(vehicle);
        const vehicleTypeForSearch = (vehicle.vehicle_type && vehicle.vehicle_type.length) ? vehicle.vehicle_type.toLowerCase() + 's' : '';
        const fuelTypeSearch = new Models.VehicleSearch("/used/", vehicleTypeForSearch);
        fuelTypeSearch.fuelType = vehicle.fuel_type;
        const transmissionSearch = new Models.VehicleSearch("/used/", vehicleTypeForSearch);
        transmissionSearch.gearboxType = vehicle.transmission;
        const manufacturerSearch = new Models.VehicleSearch("/used/", vehicleTypeForSearch);
        manufacturerSearch.make = vehicle.manufacturer;
        const modelSearch = new Models.VehicleSearch("/used/", vehicleTypeForSearch);
        modelSearch.make = vehicle.manufacturer;
        modelSearch.model = vehicle.model;
        const colourSearch = new Models.VehicleSearch("/used/", vehicleTypeForSearch);
        colourSearch.colour = vehicle.basic_colour;
        const bodyTypeSearch = new Models.VehicleSearch("/used/", vehicleTypeForSearch);
        bodyTypeSearch.bodyType = vehicle.body_type;
        const linkableFieldTemplate = vehicle.sold
            ? (html: string, href: string) => html
            : (html: string, href: string) => `<a href=\"${href}\">${html}</a>`;
        const whatsapp = `${window.location.protocol}//${window.location.hostname}${vehicle.urlPath} - ${vehicle.year_built} ${vehicle.manufacturer_display} ${vehicle.model_display} ${StringFormatting.coerceNullishToBlank(vehicle.derivative_display)} (${VehicleStringFormatting.price(vehicle.price, vehicle.sold, null, vehicle.has_price_from)})`;
        // Cache of the template
        const template = templateEl;

        // Get the contents of the template
        const templateHtml = template.innerHTML;
        // Replace placeholder tags
        // with actual data, and generate final HTML
        const phoneNumbers = branch.departments
            .map(d => d.departmentalPhoneNumbers.map(buildDealerContactPhoneDetails))
            .reduce((a,b) => a.concat(b), [])
            .slice(0, pageConfig.maxDealerPhoneNumbers)
            .join("");

        // Final HTML variable as empty string
        const listHtml = templateHtml.replace(/%vehicle_id%/g, vehicle.id)
            .replace(/%vehicle_type_plural%/g, (vehicle.vehicle_type && vehicle.vehicle_type.length) ? vehicle.vehicle_type.toLowerCase() + 's' : '')
            .replace(/%vehicle_year%/g, StringFormatting.numberToString(vehicle.year_built, ""))
            .replace(/%vehicle_make_link%/g, linkableFieldTemplate(vehicle.manufacturer_display, Page.getSearchUrl(manufacturerSearch, true)))
            .replace(/%vehicle_make%/g, vehicle.manufacturer)
            .replace(/%vehicle_make_display%/g, vehicle.manufacturer_display)
            .replace(/%vehicle_model_link%/g, linkableFieldTemplate(vehicle.model_display, Page.getSearchUrl(modelSearch, true)))
            .replace(/%vehicle_model%/g, vehicle.model)
            .replace(/%vehicle_model_display%/g, vehicle.model_display)
            .replace(/%vehicle_derivative%/g, vehicle.derivative)
            .replace(/%vehicle_derivative_display%/g, StringFormatting.coerceNullishToBlank(vehicle.derivative_display))
            .replace(/%vehicle_body_link%/g, linkableFieldTemplate(vehicle.body_name, Page.getSearchUrl(bodyTypeSearch, true)))
            .replace(/%vehicle_colour_link%/g, linkableFieldTemplate(vehicle.colour, Page.getSearchUrl(colourSearch, true)))
            .replace(/%vehicle_price%/g, VehicleStringFormatting.price(vehicle.price, vehicle.sold,null,vehicle.has_price_from))
            .replace(/%vehicle_list_price%/g, VehicleStringFormatting.listPrice(vehicle.list_price, vehicle.sold, ""))
            .replace(/%vehicle_discount_amount%/g, VehicleStringFormatting.savingPrice(vehicle.discount_amount, vehicle.sold, ""))
            .replace(/%vehicle_discount_price%/g, VehicleStringFormatting.price(vehicle.discount_price, vehicle.sold, ""))
            .replace(/%vehicle_gallery%/g, gallery)
            .replace(/%vehicle_thumbs%/g, thumbs)
            .replace(/%vehicle_whatsapp%/g, encodeURIComponent(whatsapp))
            .replace(/%vehicle_video%/g, (hasVideo) ? VehicleStringFormatting.getVideoHtml(videoId) : "")
            .replace(/%vehicle_hidetabs%/g, (hasVideo) ? "" : "hidden")
            .replace(/%vehicle_mileage%/g, StringFormatting.numberWithCommas(vehicle.mileage, ""))
            .replace(/%vehicle_engine_link%/g, linkableFieldTemplate(vehicle.engine, Page.getSearchUrl(fuelTypeSearch, true)))
            .replace(/%vehicle_gearbox_link%/g, linkableFieldTemplate(vehicle.gearbox, Page.getSearchUrl(transmissionSearch, true)))
            .replace(/%vehicle_capacity%/g, VehicleStringFormatting.engineSize(vehicle.engine_capacity))
            .replace(/%vehicle_insurance%/g, StringFormatting.coerceNullishToBlank(vehicle.insurance_group))
            .replace(/%vehicle_mpg%/g, VehicleStringFormatting.mpg(vehicle.mpg))
            .replace(/%vehicle_urban_mpg%/g, VehicleStringFormatting.mpg(vehicle.urban_mpg))
            .replace(/%vehicle_extra_urban_mpg%/g, VehicleStringFormatting.mpg(vehicle.extra_urban_mpg))
            .replace(/%vehicle_co2%/g, VehicleStringFormatting.co2(vehicle.co2))
            .replace(/%vehicle_tax%/g, VehicleStringFormatting.taxRate(vehicle.tax_rate_12))
            .replace(/%vehicle_height%/g, VehicleStringFormatting.height(vehicle.height))
            .replace(/%vehicle_width%/g, VehicleStringFormatting.width(vehicle.width))
            .replace(/%vehicle_length%/g, VehicleStringFormatting.length_(vehicle.length))
            .replace(/%vehicle_acceleration%/g, VehicleStringFormatting.acceleration(vehicle.acceleration))
            .replace(/%vehicle_bhp%/g, VehicleStringFormatting.bhp(vehicle.bhp))
            .replace(/%vehicle_topspeed%/g, VehicleStringFormatting.speed(vehicle.max_speed))
            .replace(/%vehicle_branch%/g, vehicle.branch_name)
            .replace(/%vehicle_description%/g, description)
            .replace(/%vehicle_fuel_type%/g, vehicle.fuel_type)
            .replace(/%vehicle_features_bulk%/g, this.buildVehicleFeaturesList(vehicle.features))
            .replace(/%vehicle_features_title%/g, `${vehicle.manufacturer_display} ${vehicle.model_display} Features`)
            .replace(/%vehicle_datasheet%/g, vehicle.datasheetPath)
            .replace(/%vehicle_sash%/g, VehicleStringFormatting.sash(vehicle.vehicle_sash))
            .replace(/%vehicle_save%/g, save)
            .replace(/%vehicle_vat_exempt%/g, VehicleStringFormatting.vatExempt(vehicle.vat_exempt))
            .replace(/%vehicle_vat_excluded%/g, VehicleStringFormatting.vatExcluded(vehicle.vat_excluded))
            .replace(/%hideWhenSold%/g, vehicle.sold ? "hide" : "")
            .replace(/%vehicle_seats%/g, StringFormatting.numberToString(vehicle.seats, ""))
            .replace(/%vehicle_doors%/g, StringFormatting.numberToString(vehicle.doors, ""))
            .replace(/%vehicle_manufacturers_warrenty%/g, StringFormatting.coerceNullishToBlank(vehicle.manufacturers_warranty))
            .replace(/%vehicle_warrenty_expires%/g, StringFormatting.coerceNullishToBlank(vehicle.manufacturers_warranty_expiry))
            .replace(/%vehicle_mot_expiry%/g, StringFormatting.dateToString(vehicle.mot_expiry))
            .replace(/%vehicle_wheelbase%/g, VehicleStringFormatting.millimeters(vehicle.wheelbase))
            .replace(/%vehicle_boot_space_seats_down%/g, StringFormatting.coerceNullishToBlank(vehicle.boot_space_seats_down))
            .replace(/%vehicle_boot_space_seats_up%/g, StringFormatting.coerceNullishToBlank(vehicle.boot_space_seats_up))
            .replace(/%vehicle_unladened_weight%/g, VehicleStringFormatting.kilogrammes(vehicle.unladened_weight))
            .replace(/%vehicle_mtplm%/g, VehicleStringFormatting.kilogrammes(vehicle.max_loading_weight))
            .replace(/%vehicle_kerb_weight%/g, VehicleStringFormatting.kilogrammes(vehicle.kerb_weight))
            .replace(/%vehicle_berth%/g, StringFormatting.coerceNullishToBlank(vehicle.berth))
            .replace(/%vehicle_exhaust_manufacturer%/g, StringFormatting.coerceNullishToBlank(vehicle.exhaust_manufacturer))
            .replace(/%vehicle_muffler%/g, StringFormatting.coerceNullishToBlank(vehicle.muffler))
            .replace(/%vehicle_internal_length%/g, VehicleStringFormatting.millimeters(vehicle.internal_length))
            .replace(/%vehicle_internal_width%/g, VehicleStringFormatting.millimeters(vehicle.internal_width))
            .replace(/%vehicle_awning_size%/g, VehicleStringFormatting.millimeters(vehicle.awning_size))
            .replace(/%vehicle_category%/g, StringFormatting.coerceNullishToBlank(vehicle.category))
            .replace(/%vehicle_layout%/g, StringFormatting.coerceNullishToBlank(vehicle.layout))
            .replace(/%vehicle_max_loading_weight%/g, VehicleStringFormatting.kilogrammes(vehicle.max_loading_weight))
            .replace(/%vehicle_monthly_payment_link%/g, VehicleFinanceQuotes.setVehicleMonthlyPayment(vehicle))
            .replace(/%vehicle_details_url%/g, vehicle.urlPath)
            .replace(/%vehicle_finance_checker_url%/g, VehicleFinanceChecker.GetFinanceCheckerUrl(financeConfig, vehicle) )
            .replace(/%department_phone_numbers%/g, phoneNumbers)
            .replace(/%vehicle_files%/g, this.getVehicleFiles(vehicle.file_links))
            .replace(/%vehicle_equipment_safety%/g, this.buildVehicleFeaturesList(vehicle.equipment_safety))
            .replace(/%vehicle_equipment_security%/g, this.buildVehicleFeaturesList(vehicle.equipment_security))
            .replace(/%vehicle_equipment_entertainment%/g, this.buildVehicleFeaturesList(vehicle.equipment_entertainment))
            .replace(/%vehicle_equipment_drivers_convenience%/g, this.buildVehicleFeaturesList(vehicle.equipment_drivers_convenience))
            .replace(/%vehicle_equipment_exterior_features%/g, this.buildVehicleFeaturesList(vehicle.equipment_exterior))
            .replace(/%vehicle_equipment_interior_features%/g, this.buildVehicleFeaturesList(vehicle.equipment_interior))
            .replace(/%vehicle_equipment_technical%/g, this.buildVehicleFeaturesList(vehicle.equipment_technical))
            .replace(/%vehicle_equipment_packs%/g, this.buildVehicleFeaturesList(vehicle.equipment_packs))
            .replace(/%vehicle_equipment_trim%/g, this.buildVehicleFeaturesList(vehicle.equipment_trim))
            .replace(/%vehicle_equipment_wheels%/g, this.buildVehicleFeaturesList(vehicle.equipment_wheels))
            .replace(/%vehicle_equipment_additional%/g, this.buildVehicleFeaturesList(vehicle.equipment_additional));

        return DOMPurify.sanitize(listHtml, { ADD_ATTR: ['target'], ADD_TAGS: ['iframe'] });
    }

    private static getGalleryThumbs(vehicle: Models.Vehicle, galleryThumbSize: string) {

        if (vehicle.images.length == 0) {
            return "";
        }

        let thumbs = '<ul class="slick slick--vehicle-gallery-thumbs">';
        for (let i = 0; i < vehicle.images.length; i++) {
            thumbs += `<li><img src="${vehicle.images[i][galleryThumbSize]}" class="ga-image-carousel"></li>`;
        }
        thumbs += '</ul>';

        return thumbs;
    }

    private static getGalleryImages(vehicle: Models.Vehicle, galleryImageSize: string) {
        if (vehicle.images.length == 0) {
            switch (vehicle.vehicle_type) {
              case 'bike':
                return "<div class='awaiting-image'><img src='/basemedia/bike.png'></div>";
              break;
              case 'caravan':
                return "<div class='awaiting-image'><img src='/basemedia/caravan.png'></div>";
              break;
              case 'van':
                return "<div class='awaiting-image'><img src='/basemedia/commercial.png'></div>";
              break;
              case 'motorhome':
              case 'campervan':
                return "<div class='awaiting-image'><img src='/basemedia/motorhome.png'></div>";
              break;
              default:
                return "<div class='awaiting-image'><img src='/basemedia/car.png'></div>";
            }
        }

        let galleryImages = '<ul class="slick slick--vehicle-gallery-main">';
        for (let i = 0; i < vehicle.images.length; i++) {
            galleryImages += `<li><a href="${vehicle.images[i][galleryImageSize]}"><img src="${vehicle.images[i][galleryImageSize]}" class="ga-image-carousel"></a></li>`;
        }
        galleryImages += '</ul>';

        return galleryImages;

    }

    private static getVehicleFiles(files: Models.VehicleFile[]): string {
        if (files == null || files.length == 0) {
            return "";
        }
        let vehicleFiles = `<div class ="l-node-used-vehicle--full__files">
        <ul class="files-list">
        ${
            files.map(f => `<li><a href="${f.url}" target="_blank">${f.title}</a></li>`).join('')
            }
        </ul>
        </div>
        `;
        return vehicleFiles;
    }

    private static removeElement(el: HTMLElement) {
        if (!el || el == null) {
            return;
        }
        if (typeof el.remove === 'function') {
            el.remove();
        }
        else {
            const parent = el.parentElement;
            if (parent === null) {
                el.innerHTML = "";
            }
            else {
                parent.removeChild(el);
            }
        }
    }

    private static checkIfSaved(vehicleID: string) {
        return VehicleComparison.isVehicleInComparisonList(vehicleID);
    }

    private static toggleVehicleCompareButtonState(isSaved: boolean, compareBtnEl: HTMLElement) {
        let saveSectionExtraClasses = "saved-button success";
        if (isSaved) {
            $(compareBtnEl)
                .addClass(saveSectionExtraClasses)
                .children("i").addClass("fas").removeClass("far");

            $(compareBtnEl)
                .children("span")[0].textContent = " Compared";
        } else {
            $(compareBtnEl)
                .removeClass(saveSectionExtraClasses)
                .children("i").addClass("far").removeClass("fas");

            $(compareBtnEl)
                .children("span")[0].textContent = " Compare";
        }
    }

    private static tryExtractVideoId(url: string): string | null {
        if (url == null) {
            return null;
        }

        const regExp = /(?:https?:)?(?:\/\/)?(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/\S*?[^\w\s-])((?!videoseries)[\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/;
        const match = url.match(regExp);

        if (match) {
            return match[1];
        } else {
            return null;
        }
    }

    private static buildVehicleFeaturesList(features: string): string {
        if (features !== null && features !== 'None' && typeof features !== 'undefined' && features !== "") {
            return features.split(",").map(f => `<li>${f.replace(/^\s+|\s+$/g, "")}</li>`).join("");
        }
        else {
            return "";
        }
    }

    private static removeUnusedFeatures(featuresEl: HTMLElement, tabsUl: HTMLUListElement, tabContentSectionsContainer: HTMLElement, featuresTab: HTMLElement, features: string, description: string) {
        if (featuresEl) {
            //hide features if there aren't any:
            if (!features) {
                VehicleSingleTemplate.hideFeaturesSection(featuresEl, tabsUl, tabContentSectionsContainer, featuresTab, description);
            }

            const conditionallyRemove = (f: HTMLElement) => {
                if (f.childElementCount == 0) {
                    this.removeElement(f.parentElement);
                }
            };

            [].forEach.call(featuresEl.querySelectorAll(".features-category ul"), conditionallyRemove);

            [].forEach.call(featuresEl.querySelectorAll(".features-category div"), conditionallyRemove);
        }

    }

    private static hideFeaturesSection(contentEl: HTMLElement, tabsUl: HTMLUListElement, tabContentSectionsContainer: HTMLElement, featuresTab: HTMLElement, description: string) {
        // if features are in a tab panel, only hide the feature tabs panel if description
        // is blank or null because description is displayed in the features section
        const featuresSectionOfFeaturesPanel = contentEl.querySelector(".l-node-used-vehicle--full__features") as HTMLElement;

        if(tabsUl != null) {
            // if tab is active - set next tab to active.
            const switchToFirstInactiveTab = (featuresTab.classList.contains("is-active"));

            if(description == null || description == "") {
                // hide tab
                if (featuresTab != null) {
                    this.removeElement(featuresTab);
                }

                this.removeElement(contentEl);
            }

            if(switchToFirstInactiveTab) {
                tabsUl.children[0].classList.add("is-active");

                tabContentSectionsContainer.children[0].classList.add("is-active");
            }
        }

        // hide features list in features div... :-| naming!
        this.removeElement(featuresSectionOfFeaturesPanel);
    }

    private static removeEmptySpecListEls(specListEl: HTMLElement) {
        if (specListEl != null) {
            let blankEls: HTMLElement[] = [];
            [].forEach.call(specListEl.children, (specEl: HTMLElement) => {
                const valEls = specEl.getElementsByClassName("field-value");
                if (valEls.length) {
                    if (valEls[0].innerHTML == "") {
                        blankEls.push(specEl);
                    }
                }
            });

            [].forEach.call(blankEls, (el: HTMLElement) => { specListEl.removeChild(el); });
        }
    }

    private static removeEmptyTechSpecs(specListEl: HTMLElement) {
        if (specListEl != null) {
            let blankEls: HTMLElement[] = [];
            [].forEach.call(specListEl.children, (specEl: HTMLElement) => {
                const valEls = specEl.getElementsByClassName("field-value");

                for(var i = 0; i < valEls.length; i++) {
                    if (valEls[i].innerHTML == "") {
                        const specListElInnerListItem = valEls[i].parentElement;
                        blankEls.push(specListElInnerListItem);
                    }
                }
            });

            [].forEach.call(blankEls, (el: HTMLElement) => {
                // if once we removed the element the parent group would have no "spec" list elements (which are actually divs not list elements but hey), delete the group so the h3 header goes as well
                if(el.parentElement.getElementsByClassName("spec").length <= 1) {
                    this.removeElement(el.parentElement);
                } else {
                    // remove empty item (div) from inside list (group (div collection)) which is inside another list (parent group (li))
                    this.removeElement(el);
                }
            });

            // no inner list (divs) inside the outer grouped list of specs :-|
            const specListElInnerListItems = specListEl.getElementsByClassName("spec");
            if (specListElInnerListItems.length == 0) {

                this.removeElement(specListEl);

                var techSpecTab = document.querySelector("a[href*='tech-spec']");
                if(techSpecTab != null) {
                    // hide tab
                    this.removeElement(document.querySelector("a[href*='tech-spec']").parentElement);
                }

                // hide tab content
                var techSpecContent = document.querySelector("#tech-spec");
                if(techSpecContent != null) {
                    this.removeElement(document.querySelector("#tech-spec"));
                }
            }
        }
    }


    private static setUpAccordions(accordions: HTMLElement) {
        if (accordions != null) {
            const openallbutton = accordions.querySelector<HTMLElement>("#openall");

            openallbutton.addEventListener("click", (ev: MouseEvent) => {
                this.toggleAllAccordions(accordions);
            });

            [].forEach.call(accordions.querySelectorAll<HTMLElement>(".accordion"), (el: HTMLElement) => {
                el.addEventListener("click", (ev: MouseEvent) => {
                    this.toggleAccordion(el);
                })
            });
        }
    }

    private static toggleAccordion(accordion: HTMLElement) {
        accordion.classList.toggle("active");
        let panel = accordion.parentElement.querySelector<HTMLElement>('.panel');
        panel.style.display = (panel.style.display === "block" ? "none" : "block");
    }

    private static toggleAllAccordions(accordions: HTMLElement) {
        //
        const openallbutton = document.querySelector<HTMLElement>("#openall");

        if (openallbutton.classList.contains("openall")) {
            openallbutton.classList.remove("openall");
            openallbutton.innerText = "Expand All +";
            [].forEach.call(accordions.querySelectorAll<HTMLElement>(".accordion"), (el: HTMLElement) => {
                el.classList.remove("active");
                el.parentElement.querySelector<HTMLElement>('.panel').style.display = "none";
            });
        }
        else {
            openallbutton.classList.add("openall");
            openallbutton.innerText = "Collapse All -";
            [].forEach.call(accordions.querySelectorAll<HTMLElement>(".accordion"), (el: HTMLElement) => {
                el.classList.add("active");
                el.parentElement.querySelector<HTMLElement>('.panel').style.display = "block";
            });
        }
    }

    private static purifyLinks() {
        DOMPurify.addHook('afterSanitizeAttributes', function (node) {
            // set all elements owning target to target=_blank

            if (node.tagName === "IFRAME") {
                let iframe = node as HTMLIFrameElement;
                if (iframe.src.indexOf(`https://www.youtube.com/embed/`) !== 0) {
                    node.remove();
                }
            }
            if ("target" in node == true) {
                let anchorNode = node as HTMLAnchorElement;
                if (anchorNode.target.length > 0 && anchorNode.target !== "_self") {
                    node.setAttribute('target', '_blank');
                    // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
                    node.setAttribute('rel', 'noopener noreferrer');
                }
            }
        });
    }
}
